import { HttpS } from "./HttpS";

export type PassPlan = {
  id: string;
  label: string;
  price: number;
  days: number;
  color: string;
  longName: string;
  desc: string;
  chargebeeId: string;
  trial?: boolean;
};

const PASS: Array<PassPlan> = [
  {
    id: "6bbe46b3-1b92-4c88-a0b3-706b59e04a4b",
    label: "T",
    price: 0,
    days: 10,
    color: "#BBDEFB",
    longName: "",
    desc: "Test",
    chargebeeId: "pass-T-EUR",
    trial: true,
  },
  {
    id: "aedb546a-7741-4782-9cf8-2f0042a0c9c6",
    label: "S",
    price: 19,
    days: 10,
    color: "#c9e265",
    longName: "SPAß",
    desc: "auf Probe",
    chargebeeId: "pass-S-EUR",
  },
  {
    id: "1e56f721-cdfd-4f5d-be7d-d6e2173ca5b3",
    label: "M",
    price: 39,
    days: 90,
    color: "#fae318",
    longName: "Mehr",
    desc: "Erleben",
    chargebeeId: "pass-M-EUR",
  },
  {
    id: "591c5323-c998-4f85-bfb7-d148cc190e38",
    label: "L",
    price: 79,
    days: 365,
    color: "#fa9907",
    longName: "Langer",
    desc: "Freundschafts",
    chargebeeId: "pass-L-EUR",
  },
];

const TrialPass = PASS[0];

export type Ticket = {
  id: string;
  validTill: Date;
  dealTitle: string;
  dealPartner: string;
  redeemedAt: Date;
};

export type Pass = {
  passLabel: string;
  validTill: Date;
  ticket: Ticket;
  description?: string;
};

const getMyPass = (): Promise<Pass | undefined> => {
  return HttpS.get(`${process.env.REACT_APP_DEAL_SERVICE_URL}/pass`).then(
    (resp: Response) => {
      if (resp.ok) {
        return resp.json();
      }
    }
  );
};

const createTicket = (dealId: string): Promise<boolean> => {
  return HttpS.post(`${process.env.REACT_APP_DEAL_SERVICE_URL}/ticket`, {
    dealId,
  }).then((resp: Response) => resp.status === 201);
};

const getByLabel = (label: string): PassPlan | undefined => {
  return PASS.find((p) => {
    return p.label === label;
  });
};

export const PassS = {
  PASS,
  TrialPass,
  getByLabel,
  getMyPass,
  createTicket,
};
