import React, { useState, useEffect, useContext } from "react";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import MapRoundedIcon from "@mui/icons-material/MapRounded";
import NavigationIcon from "../NavigationIcon";
import { DealsContext } from "../../contexts/DealsContext";
import { WindowContext } from "../../contexts/WindowContext";

const NavigationArea = () => {
  const { setShowMapPreview } = useContext(DealsContext);
  const [showNavButton, setShowNavButton] = useState<boolean>(false);
  const { isPhoneDevice } = useContext(WindowContext);
  const scrollUp = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const scrollHandler = (e: any) =>
    setShowNavButton(e.target.documentElement.scrollTop > 0);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <>
      {!isPhoneDevice && (
        <NavigationIcon
          right={16}
          bottom={16}
          withOpacity
          label="Deals map preview"
          icon={
            <MapRoundedIcon
              style={{ color: "black" }}
              onClick={() => setShowMapPreview(true)}
            />
          }
        />
      )}
      {showNavButton && (
        <NavigationIcon
          right={16}
          bottom={100}
          withOpacity
          label="Scroll up"
          icon={
            <KeyboardDoubleArrowUpIcon
              style={{ color: "black" }}
              onClick={scrollUp}
            />
          }
        />
      )}
    </>
  );
};

export default NavigationArea;
