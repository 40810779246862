import { useContext, useState } from "react";
import { ContactContext } from "../../contexts/ContactContext";
import { WindowContext } from "../../contexts/WindowContext";
import Button from "../../atoms/inputs/Button";
import BackdropLoader from "../backdropLoader/BackdropLoader";
import { AppUserContext } from "../../contexts/AppUserContext";
import { useNavigate } from "react-router-dom";

const RedeemTrialButton = () => {
  const { pass, contact, redeemTrialPass } = useContext(ContactContext);
  const { isAuthenticated } = useContext(AppUserContext);
  const { isPhoneDevice } = useContext(WindowContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { trialRedeemedAt } = contact ?? {};

  if (trialRedeemedAt || pass || !isPhoneDevice) {
    return null;
  }

  const onClick = async () => {
    if (!isAuthenticated) {
      navigate("/register");
    } else {
      setLoading(true);
      await redeemTrialPass();
      setLoading(false);
    }
  };

  return (
    <>
      <BackdropLoader isOpen={loading} />
      <div
        style={{
          position: "fixed",
          bottom: 25,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Button
          color="secondary"
          onClick={onClick}
          label="10 Tage kostenlos testen"
          disabled={loading}
        />
      </div>
    </>
  );
};

export default RedeemTrialButton;
