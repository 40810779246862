import React, { useEffect, useState, useContext } from "react";
import { AuthS, Contact } from "../services/AuthS";
import { Pass, PassPlan, PassS } from "../services/PassS";
import { DealS } from "../services/DealS";
import { FileS } from "../services/FileS";
import _ from "lodash";
import { AppUserContext } from "./AppUserContext";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { hotjar } from "react-hotjar";

export const ContactContext = React.createContext<any>({});

export const ContactContextProvider = ({ children }: any) => {
  const { isAuthenticated } = useContext(AppUserContext);
  const navigate: NavigateFunction = useNavigate();
  const [contact, setContact] = useState<Contact | undefined>();
  const [pass, setPass] = useState<Pass | undefined>();
  const [showPass, setShowPass] = useState<boolean>(false);
  const [contactPhoto, setContactPhoto] = useState<string | null>();
  const [favoriteDeals, setFavoriteDeals] = useState<string[]>([]);

  useEffect(() => {
    if (isAuthenticated && !contact) {
      loadContact();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (contact) {
      loadPass();
      loadContactPhoto();
      loadFavouriteDeals();
      if (hotjar.initialized()) {
        hotjar.identify(contact.id.toString(), {});
      }
    }
  }, [contact]);

  useEffect(() => {
    if (showPass && !pass) {
      navigate("/purchase-pass");
      setShowPass(false);
    }
  }, [showPass]);

  const redeemTrialPass = async () => {
    const redeemed = await AuthS.redeemTrialPass();
    if (redeemed) {
      setContact((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            trialRedeemedAt: new Date(),
          };
        }
        return undefined;
      });
      loadPass().then(() => setShowPass(true));
    }
  };

  const updateContact = (patch: Contact) => {
    AuthS.updateContactInformation(patch).then((updated: boolean) => {
      if (updated) {
        setContact((prevState) => ({
          ...prevState,
          ...patch,
        }));
      }
    });
  };

  const editFavoriteDeals = (dealId: string) => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
    if (favoriteDeals) {
      const addOperation: boolean = !favoriteDeals.includes(dealId);

      DealS.editFavoriteDeals(dealId, addOperation).then((success: boolean) => {
        if (success) {
          if (addOperation) {
            setFavoriteDeals((prev) => [...prev, dealId]);
          } else {
            setFavoriteDeals(favoriteDeals.filter((id) => id !== dealId));
          }
        }
      });
    }
  };

  const uploadContactPhoto = (file: File) => {
    const renamedFile = new File([file], "profile-image");
    FileS.uploadFile(FileS.ENTITIES.PUBLIC, "photos", renamedFile).then(
      (success: boolean) => {
        if (success) {
          setContactPhoto(URL.createObjectURL(file));
        }
      }
    );
  };

  const loadContact = () => {
    AuthS.getContactInformation().then((data: Contact | undefined) => {
      if (data) {
        setContact(data);
      } else {
        console.error("could not load own contact of profile");
      }
    });
  };

  const loadPass = () =>
    PassS.getMyPass()
      .then((data: Pass | undefined) => {
        if (data) {
          const passPlan: PassPlan | undefined = PassS.getByLabel(
            data.passLabel
          );
          setPass({
            ...data,
            description: `${passPlan?.longName} ${passPlan?.desc}`,
          });
        }
      })
      .catch((err: any) => {
        console.log({ err });
      });

  const loadFavouriteDeals = () => {
    DealS.getFavoriteDeals().then((deals: string[] | undefined) => {
      if (deals) {
        setFavoriteDeals(deals);
      }
    });
  };

  const loadContactPhoto = () => {
    FileS.fetchFile(FileS.ENTITIES.PUBLIC, "photos", "profile-image")
      .then((blobObj: Blob | undefined) => {
        if (blobObj) {
          setContactPhoto(URL.createObjectURL(blobObj));
        } else {
          setContactPhoto(null);
        }
      })
      .catch(() => {
        setContactPhoto(null);
      });
  };

  return (
    <ContactContext.Provider
      value={{
        contact: contact,
        pass: pass,
        contactPhoto: contactPhoto,
        favoriteDeals: favoriteDeals,
        updateContact: updateContact,
        uploadContactPhoto: uploadContactPhoto,
        refreshPass: loadPass,
        editFavoriteDeals: editFavoriteDeals,
        showPass: showPass,
        setShowPass: setShowPass,
        redeemTrialPass,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
